.searchLinkList {
    display: flex;
    gap: 12px;

    flex-wrap: wrap;
    height: 100%;
}

.link {
    padding: 12px;
    width: calc(25% - 34px);
    border-radius: 4px;
    display: inline-flex;
    transition: background-color 300ms, color 300ms;
    font-size: 18px;
    background-color: var(--button-background-color);
    box-shadow: 0 4px 7px 3px rgb(0 0 0 / 10%);
    gap: 10px;
    height: 230px;
    align-items: center;
    flex-direction: column;
}

.linkContent {
    color: var(--text-color);
    text-decoration: none;
    text-align: center;
    font-family: inter, sans-serif;
    font-size: 24px;
    padding-top: 8px;
}

.link:hover {
    .linkContent {
        color: var(--focus-text-color);
    }

    background-color: var(--focus-color);
    font-weight: 400;
}

.icon {
    flex-shrink: 0;
}

.searchLinkInput {
    border-radius: 20px;
    height: 24px;
    padding: 12px 24px;
    width: calc(100% - 62px);
    background-color: var(--button-background-color);
    color: var(--text-color);
    font-family: inter,sans-serif;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin: 10px auto 12px;
}

.searchLinkInput > input {
    height: 24px;
    padding: 12px 24px;
    width: 100%;
    background-color: transparent;
    color: var(--text-color);
    font-family: inter,sans-serif;
    font-size: 20px;
    border: none;
    outline: none;
}

.searchLinkInput > input::placeholder {
    color: var(--placeholder-text-color);
}

.searchLinkInput > svg {
    max-width: 20px;
}
.linkFallback {
    height: 180px;
    border-radius: 4px;
    background-color: var(--fallback-color);
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100%;
}
.linkImage {
    width: fit-content;
    background-color: var(--fallback-color);
    border-radius: 4px;
    max-height: 240px;
}

@media (max-width: 1300px) {
    .link {
        width: calc(50% - 30px);
        height: 340px;
    }

    .linkFallback {
        height: 300px;
    }

    .linkImage {
        max-height: 400px;
    }
}


@media (max-width: 850px) {
    .link {
        width: calc(100% - 25px);
        font-size: 14px;
        height: 400px;
    }
    .linkFallback {
        height: 350px;
    }

    .linkImage {
        max-height: 550px;
    }
}

@media (max-width: 500px) {
    .link {
        width: calc(100% - 25px);
        font-size: 14px;
        height: 350px;
    }
    .linkFallback {
        height: 300px;
    }
    .linkImage {
        max-height: 300px;
    }
}
