.title {
    color: var(--n100);
    text-align: center;
    font-family: inter,sans-serif;
    font-size: 36px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 24px;
}

.image {
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.banner {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: auto;
    bottom: 0;
}

@media (max-width: 500px) {
    .title {
        padding-top: 12px;
        font-size: 24px;
        padding-bottom: 12px;
        margin: 0;
    }
    .image {
        width: 75px;
        height: 75px;
    }
}