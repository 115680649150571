.image {
    cursor: pointer;
}

.imageWrapper {
    width: 100%;
    gap: 8px;
    margin-top: 12px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    list-style: none;
}

.imageWrapper {
    --space: 4px;
    --width: 0;
    --height: 0;
    --min-height: 300px;
}

.imageWrapper > * {
    flex-grow: calc(var(--width) * (100000 / var(--height)));
    flex-basis: calc(var(--min-height) * (var(--width) / var(--height)));
    aspect-ratio: var(--width) / var(--height);
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.imageWrapper > * > img {
    position: absolute;
    width: 100%;
    height: 100%;
}

.imageWrapper::after {
    content: " ";
    flex-grow: 1000000000;
}

