.refSheetWrapper {
    margin-top: 48px;
    border-radius: 6px;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
    background-color: #CAFFFB;
    width: 100%;
    height: auto;
}

.refSheetErrorWrapper {
  display: none;
  width: 100%;
}

