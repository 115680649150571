.ageGateButton {
    color: var(--text-color);
    margin: auto;
    padding: 20px;
    width: calc(50% - 20px);
    text-decoration: none;
    border-radius: 8px;
    border: 0;
    display: inline-flex;
    margin-top: 10px;
    text-align: center;
    font-family: inter, sans-serif;
    transition: background-color 300ms, color 300ms;
    font-size: 18px;
    align-items: center;
    background-color: var(--button-background-color);
    box-shadow: 0 4px 7px 3px rgb(0 0 0 / 10%);
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}

@media (max-width: 700px) {
    .ageGateButton {
        width: calc(100% - 40px);
        font-size: 14px;
    }
}

.ageGateButton:hover {
    background-color: var(--focus-color);
    color: var(--focus-text-color);
    font-weight: 400;
}