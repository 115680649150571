.about {
    color: var(--text-color);
    font-family: inter,sans-serif;
    display: block;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    line-height: 1.5em;
    margin: 20px 0 24px 10px;
}

@media (max-width: 700px) {
    .about {
        font-size: 16px;
    }
}