.imagePageWrapper {
    margin: 12px;
}

.backButton {
    position: sticky;
    color: var(--n400);
    font-family: inter,sans-serif;
    font-size: 24px;
    background: var(--n100);
    padding: 6px 12px;
    width: fit-content;
    border-radius: 12px;
    bottom: 12px;
    left: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}