.scrollBody {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
}


@media (max-width: 500px) {
    .scrollBody {
        padding-left: 12px;
        padding-right: 12px;

        max-height: calc(100vh - 204px);
        overflow-y: scroll;
        margin-bottom: 0;
    }
}
